<template>
    <div>
        <el-dialog
        :title="isFlag=='add' ? '新增参数' : '编辑参数' "
		:close-on-click-modal="false" 
        :visible.sync="dialogVisible">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                 <el-form-item label="参数键名" prop="configKey">
                    <el-input v-model="ruleForm.configKey" placeholder="请输入参数键名"></el-input>
                </el-form-item>
                <el-form-item label="参数键值" prop="configValue">
                    <el-input v-model="ruleForm.configValue"  placeholder="请输入参数键值"></el-input>
                </el-form-item>
                <el-form-item label="系统内置" prop="configType">
                    <el-radio v-model="ruleForm.configType" :label="0">否</el-radio>
                    <el-radio v-model="ruleForm.configType" :label="1">是</el-radio>
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                    <el-input type="textarea" v-model="ruleForm.remark" :rows="3" placeholder="请输入备注信息"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="onCommit">保 存</el-button>
                <el-button @click="dialogVisible = false">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { msgAPI } from 'liankong-ui-api' 

  export default {
    data() {
      return {
        dialogVisible: false,
        ruleForm: {
          configKey: '',
          configValue: '',
          configType: 0,
          remark: '',
        },
        rules: {
			configKey: [
				{ required: true, message: '请输入参数键名', trigger: 'blur' },
			],
			configValue: [
				{ required: true, message: '请输入参数键值', trigger: 'blur' },
			],
			configType: [
				{ required: true, message: '请选择是否是系统内置此参数', trigger: 'blur' },
			],
			remark: [
				{ required: true, message: '请输入备注信息', trigger: 'blur' },
			],
        },
        isFlag: 'add', // 弹框标题
        rowsInfo: {},
      };
    },
    methods: {
		onLoad(isFlag, row){
			this.isFlag = isFlag;
			this.rowsInfo = row;
			if(isFlag == 'add'){
				this.ruleForm = {
					configKey: '',
					configValue: '',
					configType: 0,
					remark: '',
				}
			} else {
				this.ruleForm = {
					id:row.id,
					configKey: row.configKey,
					configValue: row.configValue,
					configType: row.configType,
					remark: row.remark,
				}
			}
		},

      // 添加、编辑
      onCommit(){
          this.$refs['ruleForm'].validate((valid) => {
              if (valid) {
                   if(this.isFlag == 'add'){
						// 添加数据
						msgAPI.addParameter(this.ruleForm).then(res=>{
							if(res.code == 0){
								this.dialogVisible = false;
								this.$parent.getList()
								this.$message.success('添加成功！');
							}else{
								this.$message.error(res.message);
							}
						}).catch(res=>{
							this.$message.error(res.message);
						})
					}else{
						// 编辑数据
						msgAPI.updateParameter(this.ruleForm).then(res=>{
							if(res.code == 0){
								this.dialogVisible = false;
								this.$parent.getList()
								this.$message.success('编辑成功！');
							}else{
								this.$message.error(res.message);
							}
						}).catch(res=>{
							this.$message.error(res.message);
						})
					}
              }
          })
      }
    }
  };
</script>
<style lang="less" scoped>
</style>